<!-- 报名管理 -->
<template>
    <div class="enrollAdmin">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" label-width="100px">
            <el-form-item label="查询日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.dateQuery" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="是否有照片">
                <el-select v-model="form.YesNo" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.YesNoOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.status" placeholder="请选择状态" :clearable="true">
                    <el-option v-for="item in form.statusOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否收款">
                <el-select v-model="form.whether" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.whetherOpt" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-form-item label="考试大类">
                <el-select v-model="form.exam" filterable placeholder="请选择考试大类" :clearable="true">
                    <el-option v-for="item in form.examOpt" :key="item.id" :label="item.exam_name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;display: flex;flex-wrap: wrap;">
                <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <button class="derive" @click="derive">导出到Excel</button>
                <button class="peg" @click="batchesSign">收款标记</button>
                <el-upload ref="upload"
                    action="https://artxt.szart.cn/api/public/index.php/api/mechanismexam/upload" 
                    :limit="1" :before-upload="beforeUpload" :data = "importData"   
                    accept=".xls,.xlsx" class="up-class" :on-success="uploadSuccess" :show-file-list="false"
                        :auto-upload="true" >
                        <el-button slot="trigger" size="small"  class="daoru"> 
                            批量导入
                        </el-button>
                    </el-upload>
                    <el-button size="small"  class="down" icon="el-icon-download" @click='download'>
                        下载导入模板
                    </el-button>
            </div>
            
            <!-- 弹出提示 -->
            <el-dialog title="提示" :visible.sync="dialog" width="30%" :before-close="handleClose">
                <span>正在导入数据中，请稍等。。。</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button style="background:#409EFF ;color: #fff;" @click="dialog = false">确 定</el-button>
                </span>
            </el-dialog>
            
            
            <div class="" style="margin-right: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="Query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe @selection-change="checkAll" style="width:97.9%;flex: 1;"
            height="660">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="status" label="状态"  align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0||scope.row.status==''"></span>
                    <span v-else-if="scope.row.status==1">草稿</span>
                    <span v-else-if="scope.row.status==2">报考</span>
                    <span v-else-if="scope.row.status==3">准考</span>
                    <span v-else-if="scope.row.status==4">考试OK</span>
                    <span v-else-if="scope.row.status==5">取消</span>
                    <span v-else-if="scope.row.status==6">退款取消</span>
                    <span v-else-if="scope.row.status==7">延期</span>
                    <span v-else="scope.row.status==8">缺考</span>
                </template>
            </el-table-column>
            <el-table-column prop="enroll_number"  label="报名单号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="certificate_no"  label="准考证号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="name" :sortable="true" :sort-method="fn2" label="学生姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="english_name" label="拼音名" align="center" width="200">
            </el-table-column>
            <el-table-column prop="idcard" label="身份证号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="birthdate" label="出生日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course" label="科目" align="center" width="150">
            </el-table-column>
            <el-table-column prop="original_level" label="原等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_level" label="考试等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="mobile1" label="联系电话" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_date" label="报名日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="head_image" label="照片" align="center" width="80">
                <template slot-scope="scope">
                    <img :src="scope.row.head_image" style="width:15px;height:20px;margin-top: 5px;">
                </template>
            </el-table-column>
            <el-table-column prop="enroll_price" label="报名费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="certificatecopy_price" label="副证费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="price1" label="考级应收" align="center" width="120">
            </el-table-column>
            <el-table-column prop="is_receipt" label="是否收款" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_receipt==0">否</span>
                    <span v-else-if="scope.row.is_receipt==1">是</span>
                </template>
            </el-table-column>
            <el-table-column prop="exam_address" label="考场地址" align="center" width="300"
            :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="exam2_date" label="考试时间" align="center" width="200">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="280">
                <template slot-scope="scope">
                    <el-button size="mini" icon="el-icon-edit" class="edit" 
                        @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button class="sign" size="mini" @click="sign(scope.$index, scope.row)"
                      v-if="scope.row.is_receipt==0"  >
                        收款标记
                    </el-button>
                    <el-button size="mini" class="Test" @click="Test(scope.$index, scope.row)"
                        v-if="scope.row.status==1">
                        报考
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <div class="txtbox">
                    报名费 <input type="text" class="itp" :value="regfee" disabled="disabled"/>
                </div>
                <div class="txtbox">
                    副证费 <input type="text" class="itp" :value="Certificate_fee" disabled="disabled"/>
                </div>
                <div class="txtbox">
                    考级应收 <input type="text" class="itp" :value="receivable" disabled="disabled"/>
                </div>
                <div class="txtbox">
                    是否收款 <input type="text" class="itp" :value="whether" disabled="disabled"/>
                </div>
            </div>
            <!--分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                startdate:"",//开始日期
                enddate:"" ,//结束日期
                form: {
                    status:0,//状态
                    statusOpt:[
                        {value:0,label: '全部'},
                        {value:1,label: '草稿'},
                        {value:2,label: '报考'},
                        {value:3,label: '准考'},
                        {value:4,label: '考试OK'}
                    ],//状态选择
                    dateQuery:[], //查询日期 
                    YesNo: "2", //是否有照片
                    YesNoOpt: [{
                            value: '0',
                            label: '否'
                        }, {
                            value: '1',
                            label: '是'
                        },{
                            value: '2',
                            label: '全部'
                        }

                    ], //是否有照片选项
                    whether: "", //是否收款
                    whetherOpt: [{
                            value: '0',
                            label: '否'
                        }, {
                            value: '1',
                            label: '是'
                        },{
                            value: '2',
                            label: '全部'
                        }

                    ], //是否收款选项
                    search: "" ,//搜索
                    examOpt:[],//考试大类
                    exam:''
                },
                tableData:[], //表格数据
                currentPage: 1,
                currentLimit: 20,
                total: 0,
                ids: [],
                regfee:"",//报名费
                Certificate_fee:"",//副证费
                receivable:"",//考级应收
                whether:"",//是否收款
                importData:{
                    uid:localStorage.getItem('token'),
                },
                dialog: false //用于判断弹窗
            }
        },
        created() {
            this.getTime()
            this.getList()
            // this.$emit("abc",JSON.parse(localStorage.getItem("get_info")))
            // 考试大类
            this.$request({
                url: '/api/ExamCategory/list',
                method: 'POST',
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log(res,"考试大类")
                if (res.code == 1) {
                    this.form.examOpt = res.data.list
                }
            })
        },
        activated() {
            this.getList()
        },
        methods: {
            getTime(){
                let now = new Date();
                let year = now.getFullYear();
                let month = now.getMonth() + 1; //0-11表示1-12月
                month = month.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
                let day = now.getDate();
                day = day.toString().padStart(2, "0"); //当小于10时补0
                this.enddate= year + '-' + month + '-' + day;
                console.log(this.enddate,"当前时间")
                let nowMonthDay = new Date(year, month, 0).getDate(); //当前月的总天数
                if (month - 4 <= 0) { //如果是1、2、3月，年数往前推一年
                    let last3MonthDay = new Date((year - 1), (12 - (4 - parseInt(month))), 0).getDate(); //3个月前所在月的总天数
                    if (last3MonthDay < day) { //3个月前所在月的总天数小于现在的天日期
                        this.enddate= (year - 1) + '-' + (12 - (4 - month)).toString().padStart(2, "0") + '-' + last3MonthDay;
                    } else {
                        this.startdate= (year - 1) + '-' + (12 - (4 - month)).toString().padStart(2, "0") + '-' + day;
                    }
                } else {
                    let last3MonthDay = new Date(year, (parseInt(month) - 4), 0).getDate(); //3个月前所在月的总天数
                    if (last3MonthDay < day) { //3个月前所在月的总天数小于现在的天日期
                        if (day < nowMonthDay) { //当前天日期小于当前月总天数,2月份比较特殊的月份
                            this.enddate= year + '-' + (month - 4).toString().padStart(2, "0") + '-' + (last3MonthDay - (nowMonthDay - day));
                        } else {
                            this.startdate=year + '-' + (month - 4).toString().padStart(2, "0") + '-' + last3MonthDay;
                        }
                    } else {
                        this.startdate=year + '-' + (month - 4).toString().padStart(2, "0") + '-' + day;
                    }
                }
                this.form.dateQuery[0] = this.startdate;
                this.form.dateQuery[1] = this.enddate;
                console.log(this.form.dateQuery,"4个月区间段")
            },
            getList() {//获取列表
                this.$request({
                    url: '/api/mechanismexam/signupList',
                    method: 'POST',
                    data: {
                        uid: localStorage.getItem('token'),
                        page: this.currentPage, //页数
                        limit: this.currentLimit, //条数
                        search: this.form.search, //关键字
                        is_receipt: this.form.whether, //是否收款
                        dateQuery: this.form.dateQuery ,//查询日期
                        is_head_image: this.form.YesNo,//是否有照片
                        status:this.form.status,//状态
                        apply_category:this.form.exam
                    }
                }).then(res => {
                    console.log(res)
                    this.tableData = res.data.list
                    this.total = res.data.count
                    this.regfee=res.data.sum_enroll_price
                    this.Certificate_fee=res.data.sum_certificatecopy_price
                    this.receivable=res.data.sum_price1
                    this.whether=res.data.sum_receipt
                })
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.currentLimit = val
                this.getList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.replace({
                    path: "/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            edit(index,row){//跳转编辑
               // EditEnrollAdmin
                 this.$router.push({
                     path:"/editEnrollAdmin",
                     query:{
                        id:row.id 
                     }
                 })
            },
            Test(index,row){//修改报考
                this.$request({url:'/api/mechanismexam/exam',method:'POST',data:{
                    uid:localStorage.getItem('token'),
                    signup_id:row.id
                }}).then(res=>{
                    console.log(res)
                    if(res.code==1){
                        this.$message({
                          message: '修改成功',
                          type: 'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                          message: res.msg,
                          type: 'error'
                        })
                    }
                })
            },
            sign(index,row) { //行内收款标记
                this.ids=[]
                this.ids.push(row.id)
                // console.log(this.ids)
                this.$request({url:'/api/mechanismexam/receipt',method:'POST',data:{
                    uid:localStorage.getItem('token'),
                    ids:this.ids 
                }}).then(res=>{
                    if(res.code==1){
                        this.$message({
                          message: '标记成功',
                          type: 'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                          message: res.msg,
                          type: 'error'
                        })
                    }
                })
            },
            checkAll(selection) { //获取批量收款id
                // console.log(selection)
                this.ids = []
                selection.forEach(item => {
                    this.ids.push(item.id)
                })
                // console.log(this.ids)
            },
            batchesSign(){//批量标记
                this.$request({url:'/api/mechanismexam/receipt',method:'POST',data:{
                    uid:localStorage.getItem('token'),
                    ids:this.ids 
                }}).then(res=>{
                    // console.log(res)
                    if(res.code==1){
                        this.$message({
                          message: '标记成功',
                          type: 'success'
                        })
                        this.getList()
                    }
                })
            },
            Query() { //查询
                // console.log(this.form)
                this.currentPage=1
                this.getList()
            },
            Reset() { //重置
                this.form.dateQuery = [] //查询日期
                this.form.YesNo = "" //是否有照片
                this.form.whether = "" //是否收款
                this.form.search = "" //搜索
                this.form.exam = ''
            },
            derive() { //导出
            // console.log(this.currentPage,this.currentLimit,localStorage.getItem('token')
            // ,this.form.dateQuery,this.form.whether,this.form.search)
                location.href =
                    `https://artxt.szart.cn/api/public/index.php/api/mechanismexam/export?
                    page=${this.currentPage}&limit=${this.currentLimit}&uid=${localStorage.getItem('token')}
                    &dateQuery=${this.form.dateQuery}&is_receipt=${this.form.whether}&search=${this.form.search}
                    &apply_category=${this.form.exam}`
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            
            // 导入
            beforeUpload(file) {
                // console.log(file)
                let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
                const isXLS = extension === 'xls';
                const isXLSX = extension === 'xlsx';
                let flag = [isXLS, isXLSX].includes(true)
                
                if (!flag) {
                    this.$message({
                        message: '上传文件只能是 xls，xlsx格式!',
                        type: 'warning'
                    });
                    return false;
                }
                this.dialog = true
            },
            uploadSuccess(res, file, fileList) {
                this.dialog = false
                if (res.code == 200) {
                    this.$message({
                        message: '导入成功!',
                        type: 'success'
                    });
                } else {
                    this.$alert(res.msg, '导入失败', {
                        confirmButtonText: '确定',
                    });
                }
                this.$refs.upload.clearFiles();
            },
            
            download(){//下载导入模板
                window.open('https://artxt.szart.cn/报名模板.xls')
            },
            // 姓名排序
            fn2(a, b){
                return a.name.localeCompare(b.name)
            }
        }
    }
</script>

<style scoped="scoped">
    .enrollAdmin {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        width: 245px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    /* 导出按钮 */
    .derive {
        display: inline-block;
        width: 113px;
        height: 30px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/export.png) 6px 6px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        margin: 0px 10px;
        cursor: pointer;
    }

    /* 收款标记按钮 */
    .peg {
        display: inline-block;
        width: 90px;
        height: 30px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/biaoji.png) 6px 8px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        cursor: pointer;
    }
    /* 导入按钮 */
    .daoru {
        margin-left: 10px;
        padding-left: 30px;
        background: url(../../assets/import.png) 10px 6px no-repeat #FF7300;
        color: #FFFFFF;
    }
    /* 下载导入模板按钮 */
    .down{
        margin-left: 10px;
        color: #FFFFFF;
        background: #FF7300;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 28px;
    }
    .edit{
        background: #18BC9C;
        color: #fff;
    }
    .sign {
        padding-left: 25px;
        background: url(../../assets/xiaobj.png) 6px 6px no-repeat #FF7300;
        color: #FFFFFF;
    }

    .Test {
        background-color: #18BC9C;
        color: #FFFFFF;
    }

    /* 光标默认样式 */
    /* .el-button.is-disabled{
        cursor:default ;
    } */
    .txtbox{
        height: 19px;
        display: inline-block;
        margin-bottom: 18px;
        margin-right: 10px;
        font-size: 14px;
        color: #000000;
    }
    .itp{
        padding-left: 5px;
        width: 120px;
        height: 25px;
        outline: none;
        font-size: 14px;
        background-color: #FFFFFF;
        border: 1px solid #ccc;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
